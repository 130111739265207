<template>
  <focus-trap v-model:active="open">
    <header class="relative">
      <div class="fixed top-0 left-0 z-30 w-full bg-white/80 backdrop-blur-sm transition-colors" :class="{'!bg-white': open}">
        <div class="py-4 lg:py-3 border-b border-grey">
          <div class="mx-auto max-xl:px-4 xl:container flex justify-between">
            <nav class="flex items-center gap-4 lg:gap-8">

              <NuxtLink to="/" @click="open = false;">
                <svg class="h-[22px] w-auto hover:opacity-90 transition-opacity duration-300" width="89" height="22" viewBox="0 0 1502 373" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <title>Eupry</title>
                  <path d="M90.6517 173.062C90.6517 223.128 50.0656 263.714 0 263.714L3.60228e-06 346.125C95.4889 346.125 172.915 268.789 173.062 173.335C173.209 268.79 250.635 346.126 346.124 346.126L346.124 263.715C296.059 263.715 255.473 223.129 255.473 173.063L90.6517 173.062Z" fill="#070A26"/>
                  <path d="M263.714 173.063C263.714 122.997 223.128 82.4112 173.062 82.4112C122.997 82.4114 82.411 122.997 82.411 173.062L0.000377137 173.062C0.000372959 77.4827 77.483 4.17792e-06 173.063 0C268.642 0.000189571 346.125 77.4833 346.125 173.063H263.714Z" fill="#070A26"/>
                  <path d="M852.464 186.263V70.5669H899.088V286.418H863.223C857.281 286.418 852.464 281.601 852.464 275.659V258.789C838.218 280.806 816.201 292.03 785.55 292.03C735.904 292.03 702.663 258.358 702.663 203.1V70.5669H749.287V198.351C749.287 230.729 767.85 248.428 797.638 248.428C830.447 248.428 852.464 228.138 852.464 186.263Z" fill="#070A26"/>
                  <path d="M1048.52 64.9536C1078.31 64.9536 1103.35 76.1779 1124.5 98.1947C1145.65 120.212 1156.01 146.977 1156.01 178.491C1156.01 210.437 1145.65 237.203 1124.5 259.22C1103.35 281.237 1078.31 292.029 1048.52 292.029C1015.71 292.029 990.671 279.942 972.971 255.766V372.758H926.347V70.5658H962.212C968.154 70.5658 972.971 75.3828 972.971 81.325V101.648C990.671 77.0413 1015.71 64.9536 1048.52 64.9536ZM992.398 228.137C1005.35 241.088 1021.75 247.564 1041.18 247.564C1060.61 247.564 1077.01 241.088 1089.96 228.137C1102.91 214.754 1109.39 198.35 1109.39 178.491C1109.39 158.633 1102.91 142.228 1089.96 129.277C1077.01 115.895 1060.61 109.419 1041.18 109.419C1021.75 109.419 1005.35 115.895 992.398 129.277C979.447 142.228 972.971 158.633 972.971 178.491C972.971 198.35 979.447 214.754 992.398 228.137Z" fill="#070A26"/>
                  <path d="M1212.55 70.5659C1218.49 70.5659 1223.31 75.383 1223.31 81.3252V106.829C1234.97 80.0634 1261.2 70.5659 1281.83 70.5659L1302.03 117.342C1279.28 115.237 1260.12 123.706 1247.15 135.235C1233.45 147.401 1223.31 166.05 1223.31 189.691V286.417H1176.69V70.5659H1212.55Z" fill="#070A26"/>
                  <path d="M1397.1 227.274L1452.35 70.5659H1502L1419.11 293.325C1398.39 349.014 1363.42 375.78 1313.78 372.758V329.156C1343.13 330.883 1359.54 316.636 1371.19 286.849L1373.35 282.532L1281.83 70.5659L1332.77 70.5659L1397.1 227.274Z" fill="#070A26"/>
                  <path d="M508.311 197.557C511.738 213.836 519.592 226.403 531.873 235.256C544.154 243.825 559.148 248.109 576.855 248.109C601.417 248.109 619.838 239.255 632.119 221.548L670.247 243.824C649.112 274.955 617.839 290.52 576.427 290.52C541.583 290.52 513.452 279.953 492.032 258.819C470.612 237.398 459.902 210.409 459.902 177.851C459.902 145.863 470.469 119.16 491.603 97.7397C512.738 76.034 539.87 65.1812 573 65.1812C604.416 65.1812 630.12 76.1768 650.112 98.168C670.389 120.159 680.528 146.863 680.528 178.279C680.528 183.134 679.957 189.56 678.815 197.557H508.311ZM507.883 159.858H633.833C630.691 142.436 623.408 129.299 611.984 120.445C600.846 111.591 587.708 107.164 572.571 107.164C555.435 107.164 541.155 111.877 529.731 121.302C518.307 130.727 511.024 143.579 507.883 159.858Z" fill="#070A26"/>
                </svg>
              </NuxtLink>
              
              <ul class="max-lg:hidden flex gap-3 lg:gap-6 max-lg:text-sm">
                <li v-for="(item, index) in navigation.data">
                  <NuxtLink v-if="item.attributes.submenu.length === 0" :to="item.attributes.link" :class="{'border-black': isActive(item.attributes.link), 'border-transparent': !isActive(item.attributes.link)}" class="inline-block border-b hover:text-dark-grey transition-color duration-300"> {{ item.attributes.title }} </NuxtLink>
                  <button v-if="item.attributes.submenu.length > 0" @click="activeMenu.includes(index) ? activeMenu = [] : activeMenu = [index];" type="button" class="inline-flex items-center justify-center gap-1 border-b border-transparent hover:text-dark-grey transition-color duration-300">
                    <span>{{ item.attributes.title }}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                      <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                    </svg>
                  </button>
                  <div v-if="item.attributes.submenu.length > 0">
                    <Transition enter-active-class="duration-500 ease-out"
                                enter-from-class="opacity-0"
                                enter-to-class="opacity-100"
                                leave-active-class="duration-300 ease-in"
                                leave-from-class="opacity-100"
                                leave-to-class="opacity-0"
                    >
                      <div v-if="activeMenu.includes(index)" v-click-away="onClickAway" class="fixed -translate-x-3/4 lg:-translate-x-1/3 xl:translate-x-0 pt-6">
                        <div class="min-w-[200px] bg-white drop-shadow-lg py-3 rounded-lg flex gap-16">
                          <div class="w-full" v-for="submenu in item.attributes.submenu">
                            <div v-if="submenu.__component === 'navigation.submenu'">
                              <p v-if="submenu.title" class="font-medium text-xs my-3 px-6 leading-tight">{{ submenu.title }}</p>
                              <ul class="text-sm">
                                <li class="px-6 cursor-pointer" :class="{'border-t border-grey mt-[10px] pt-[15px]': subitem.topBorder, 'py-2': !!subitem.link}" v-for="subitem in submenu.menuItem">
                                  <p v-if="!subitem.link" class="font-semibold text-[13px] leading-tight tracking-normal mb-[8px] cursor-default" :class="subitem.topBorder ? 'mt-[5px]' : 'mt-[8px]' ">{{ subitem.title }}</p>
                                  <NuxtLink v-else class="flex gap-3 items-center hover:text-dark-grey transition-color duration-300" :to="subitem.link"><Icon v-if="subitem.icon !== 'none'" :name="subitem.icon" :classes="'text-black/60 w-4 h-4'" /> {{ subitem.title }} </NuxtLink>
                                </li>
                              </ul>
                            </div>
                            <div v-if="submenu.__component === 'navigation.featured-link'" class="pr-6 py-3">
                              <div class="bg-light-grey border border-grey rounded-md p-4 relative min-w-[230px]">
                                <span class="text-xxs bg-grey text-black/50 font-medium px-2 py-1 rounded-sm">{{ submenu.tag }}</span>
                                <p class="mt-3 max-w-[200px] font-medium text-sm">{{ submenu.title }}</p>
                                <NuxtLink class="mt-12 text-xs flex item-center gap-2 leading-none hover:text-dark-grey transition-color duration-300" :to="submenu.link.href"><span>{{ submenu.link.title }}</span> <Icon name="ArrowRight"/></NuxtLink>
                                <div class="absolute bottom-0 right-0">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="72" viewBox="0 0 48 72" fill="none">
                                    <path d="M48.0001 29.7144C37.9012 29.7144 29.7144 37.9012 29.7144 48.0001" stroke="#F1A7F2" stroke-width="11.4286"/>
                                    <path d="M29.7143 0.000121762C29.7143 10.0991 37.9011 18.2859 48.0001 18.2859" stroke="#F1A7F2" stroke-width="11.4286"/>
                                    <path d="M0.000119364 66.2856C10.0991 66.2856 18.2859 58.0988 18.2859 47.9999" stroke="#F1A7F2" stroke-width="11.4286"/>
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Transition>
                  </div>
                </li>
              </ul>
            </nav>
            <div class="max-lg:hidden flex items-center gap-4">
              <div>
                <button class="max-lg:text-sm text-center hover:text-dark-grey transition-color duration-300 inline-flex gap-1 justify-center items-center" @click="activeMenu.includes(999999) ? activeMenu = [] : activeMenu = [999999];">
                  <span>{{ settings.data.attributes.menu.title }}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                      <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                    </svg>
                </button>
                <div v-if="settings.data.attributes.menu.menuItem.length > 0">
                  <Transition enter-active-class="duration-500 ease-out"
                              enter-from-class="opacity-0"
                              enter-to-class="opacity-100"
                              leave-active-class="duration-300 ease-in"
                              leave-from-class="opacity-100"
                              leave-to-class="opacity-0"
                  >
                    <div v-if="activeMenu.includes(999999)" v-click-away="onClickAway" class="fixed -translate-x-3/4 lg:-translate-x-1/3 xl:translate-x-0 pt-6">
                      <div class="min-w-[200px] bg-white drop-shadow-lg py-3 rounded-lg flex gap-16">
                        <ul class="text-sm">
                          <li class="py-2 px-6 cursor-pointer" :class="{'border-t border-grey': subitem.topBorder}" v-for="subitem in settings.data.attributes.menu.menuItem">
                            <NuxtLink class="flex gap-3 items-center hover:text-dark-grey transition-color duration-300" :to="subitem.link"><Icon v-if="subitem.icon !== 'none'" :name="subitem.icon" :classes="'text-black/60 w-4 h-4'" /> {{ subitem.title }} </NuxtLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Transition>
                </div>
              </div>
              <span class="border-r border-grey h-full"></span>
              <Button :title="settings.data.attributes.button.title" size="login" variant="tertiary" :link="settings.data.attributes.button.href" />
            </div>
            <button class="flex items-center justify-center lg:hidden bg-transparent duration-500 p-2.5 -m-2.5 hover:bg-black/5 rounded" name="Menu"
                    aria-label="Toggle Menu"
                    type="button"
                    @click="open = !open; activeMenu = []">
                    <span class="inline-block transition-transform duration-500" :class="{
                      'rotate-90': open
                    }">
                    <Icon name="Burgermenu"/>
                  </span>
                  </button>
          </div>
        </div>
        <Collapse :when="open" class="v-collapse">
          <nav v-if="open" class="lg:hidden pt-4 pb-8 bg-white w-full h-[calc(100vh-50px)]">
            <div class="container mx-auto sm:h-full flex flex-col justify-between h-full">
              <div class="relative flex-1">
                <ul class="text-xl grid">
                  <li v-for="(item, index) in navigation.data">
                    <NuxtLink class="block py-2 hover:text-dark-grey transition-color duration-300" v-if="item.attributes.submenu.length === 0" :to="item.attributes.link"> {{ item.attributes.title }} </NuxtLink>
                    <button @click="activeMenu = [index]" class="block py-2.5 flex justify-between items-center w-full hover:text-dark-grey transition-color duration-300" v-if="item.attributes.submenu.length > 0">{{ item.attributes.title }} <Icon name="ArrowRight"/></button>

                    <div v-if="item.attributes.submenu.length > 0">
                      <Transition enter-active-class="duration-500 ease-out"
                                  enter-from-class="translate-x-full"
                                  enter-to-class="translate-x-0"
                                  leave-active-class="duration-500 ease-in"
                                  leave-from-class="translate-x-0"
                                  leave-to-class="translate-x-full"
                      >
                        <div v-if="activeMenu.includes(index)" class="absolute inset-0 h-full w-full bg-white overflow-y-scroll">
                          <button @click="activeMenu = []" class="flex items-center gap-7 text-black/50 text-xl py-2.5"><Icon name="ArrowLeft"/>Back</button>
                          <div class="w-full mb-8" v-for="submenu in item.attributes.submenu">
                            <div v-if="submenu.__component === 'navigation.submenu'">
                              <p v-if="submenu.title" class="font-medium mt-3 text-xl">{{ submenu.title }}</p>
                              <ul class="grid text-lg">
                                <li class="cursor-pointer" :class="{'border-t border-grey': subitem.topBorder}" v-for="subitem in submenu.menuItem">
                                  <p v-if="!subitem.link" class="font-medium mt-3 text-xl">{{ subitem.title }}</p>
                                  <NuxtLink v-else class="flex gap-3 items-center py-2 hover:text-dark-grey transition-color duration-300" :to="subitem.link"> {{ subitem.title }} </NuxtLink>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </Transition>
                    </div>
                  </li>
                </ul>
                <div v-if="settings.data.attributes.menu.menuItem.length > 0">
                  <Transition enter-active-class="duration-500 ease-out"
                              enter-from-class="translate-x-full"
                              enter-to-class="translate-x-0"
                              leave-active-class="duration-500 ease-in"
                              leave-from-class="translate-x-0"
                              leave-to-class="translate-x-full"
                  >
                    <div v-if="activeMenu.includes(9999)" class="absolute inset-0 h-full w-full bg-white overflow-y-scroll">
                      <button @click="activeMenu = []" class="flex items-center gap-7 text-black/50 text-xl py-2.5"><Icon name="ArrowLeft"/>Back</button>
                      <div class="w-full mb-8">
                        <ul class="grid text-lg">
                          <li class="cursor-pointer" :class="{'border-t border-grey': subitem.topBorder}" v-for="subitem in settings.data.attributes.menu.menuItem">
                            <NuxtLink class="flex gap-3 items-center py-2 hover:text-dark-grey transition-color duration-300" :to="subitem.link"> {{ subitem.title }} </NuxtLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Transition>
                </div>
              </div>
              <div class="grid gap-4">
                <div>
                  <button @click="activeMenu = [9999]" class="block w-full flex items-center justify-between text-xl py-2"><span>{{ settings.data.attributes.menu.title }}</span><Icon name="ArrowRight"/></button>
                </div>
                <Button :title="settings.data.attributes.button.title" size="login" variant="tertiary" :link="settings.data.attributes.button.href" />
              </div>
            </div>
          </nav>
        </Collapse>
      </div>
    </header>
  </focus-trap>
</template>

<style>
  .v-collapse {
    transition: height 500ms cubic-bezier(0.33, 1, 0.68, 1);
  }
</style>

<script lang="ts">
  import { directive as clickAwayDirective } from "vue3-click-away";

  export default {
    data () {
      return {
        open: false,
        activeMenu: [],
      }
    },

    directives: {
      ClickAway: clickAwayDirective,
    },

    methods: {
      onClickAway( event ) {
        this.activeMenu = []
      },
    },
  }
</script>

<script setup lang="ts">
  import { computed, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import { Collapse } from 'vue-collapsed';
  import { FocusTrap } from 'focus-trap-vue';
  import Button from "../components/components/Button.vue";
  import Icon from "../components/components/Icon.vue";
  import { useAsyncData, useFetch, useRuntimeConfig } from "nuxt/app";

  const route = useRoute();
  const runtimeConfig = useRuntimeConfig();
  const { data: settings, pending: pendingSettings } = useFetch(runtimeConfig.public.STRAPI_API_URL + '/header-setting?pLevel', {
    headers: {
      "Strapi-Response-Format": "v4",
    }
  });
  const { data: navigation, pending } = useAsyncData('navigation', async () => {
    const navigation = await $fetch(runtimeConfig.public.STRAPI_API_URL + '/navigations?pLevel', {
      headers: {
      "Strapi-Response-Format": "v4",
    }
    });
    navigation.data.sort((a, b) => a.attributes.order - b.attributes.order);
    return navigation
  });

  // Computed property to check if the link is active
  const isActive = computed(() => {
    return (link: string) => route.path === link;
  });
</script>